.mdl-desc-cont {
  .mdl-head {
    text-align: center;
    font-weight: 500;
    font-size: 2.2rem;
    color: #fff;

    @include Pads() {
      font-size: 2.7rem;
    }

    @include Laptops() {
      font-size: 2.7rem;
    }

    @include HiDPI() {
      font-size: 2.7rem;
    }
  }

  height: inherit;
  display: flex;
  justify-content: space-between;
  padding: 10rem 0 5rem;
  flex-direction: column;

  .mdl-desc {
    @include Laptops() {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 2rem;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    @include HiDPI() {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 2rem;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .mdl-cont {
      display: flex;
      margin: 2rem 0;
      color: #fff;

      @include Laptops() {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 2rem;
        justify-content: space-between;
        align-items: center;
      }

      @include HiDPI() {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 2rem;
        justify-content: space-between;
        align-items: center;
      }

      .mdl-spec {
        text-align: center;
        margin: 0 1rem;

        .mdl {
          display: flex;
          align-items: baseline;
          margin-bottom: 0.5rem;
          justify-content: center;

          h3 {
            margin-right: 0.1rem;
            font-weight: 500;
            font-size: 1.5rem;

            @include Pads() {
              font-size: 2rem;
            }

            @include HiDPI() {
              font-size: 2rem;
            }
          }

          span {
            font-size: 0.9rem;
          }
        }
      }
    }

    button {
      width: 100%;
      background: transparent;
      border: 2px solid #fff;
      color: #fff;
      padding: 0.6rem 0;
      border-radius: 22px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.5s ease;

      @include Laptops() {
        width: 220px;
      }

      @include HiDPI() {
        width: 220px;
      }
    }

    button:hover {
      background: #fff;
      color: #282828;
    }
  }
}
