.name {
  width: 80%;
  margin: 1rem auto;
  text-align: center;

  h1 {
    font-weight: 500;
    font-size: 1.8rem;
  }

  h4 {
    font-weight: 400;
    color: rgb(160, 160, 160);
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }
}
