.c-lang {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  grid-gap: 0.4rem;
  border-radius: 19px;
  cursor: pointer;
  padding: 0.6rem 0.7rem;
  background: transparent;
  transition: background 0.5s ease;
  margin: 0.5rem;
  z-index: 130;

  h1 {
    font-size: 0.9rem;
    background: none;
    outline: none;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }
}

.c-lang:hover {
  background: rgba(240, 240, 240, 0.9);
}
