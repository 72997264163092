.checkout {
  padding: 2rem 0;
  margin-top: 2rem;

  h1 {
    font-size: 1.4rem;
    font-weight: 500;
    color: #282828;
  }

  .checkout-container {
    padding: 1rem 0;

    .c-c-box {
      padding: 1rem 0;

      label {
        display: block;
        width: 100%;
        font-size: 0.9rem;
        color: #282828;
        font-weight: 500;
        padding: 0 0 0.5rem 1rem;
      }

      input {
        width: 100%;
        padding: 0.6rem 1rem;
        border-radius: 22px;
        background: rgb(245, 245, 245);
        border: 1px solid transparent;
        outline: none;
        transition: border 0.3s ease;
        font-weight: 500;
      }

      input:focus {
        border: 1px solid #cecece;
      }

      h5 {
        padding: 1rem 0 1rem 1rem;
        color: #282828;
        font-weight: 500;
      }

      .phone-box {
        display: flex;

        select {
          width: 30%;
          padding: 0.6rem 1rem;
          border-radius: 22px;
          background: rgb(245, 245, 245);
          border: 1px solid transparent;
          outline: none;
          transition: border 0.3s ease;
          font-weight: 500;
          margin-right: 2rem;
        }

        input {
          width: 70%;
          padding: 0.6rem 1rem;
          border-radius: 22px;
          background: rgb(245, 245, 245);
          border: 1px solid transparent;
          outline: none;
          transition: border 0.3s ease;
          font-weight: 500;
        }

        input::placeholder {
          color: #cecece;
        }
      }

      .agree-head {
        padding: 2rem 1rem;
        color: #383838;
        font-weight: 300;
      }
    }

    .d-tdy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;

      h4 {
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 0.9rem;
        color: #383838;
      }
    }

    h5 {
      color: rgb(77, 77, 77);
      font-weight: 300;
      padding-bottom: 3rem;

      span {
        color: #155fff;
        font-weight: 500;
      }
    }
  }

  button {
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 22px;
    border: none;
    background-color: rgb(51, 109, 255);
    transition: background 0.4s linear;
    color: #f0f0f0;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.8rem;
  }

  button:hover {
    background-color: rgb(17, 84, 252);
  }
}
