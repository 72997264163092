.blur-container-desktop {
  height: 100vh;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  backdrop-filter: blur(5px);
  width: 100%;
  background-color: rgba(61, 61, 61, 0.2);
  display: none;

  @include Laptops {
    display: block;
  }

  @include HiDPI {
    display: block;
  }
}

.mobile-menu {
  height: 100vh;
  z-index: 25;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  background-color: rgb(251, 251, 251);
  // width: 40%;
  // opacity: 0;
  // box-shadow: rgba(61, 61, 61, 0.2);

  .button {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;

    button {
      font-size: 1.2rem;
      border: none;
      background: transparent;
      font-weight: 400;
      cursor: pointer;
      padding: 0.4rem 0.8rem;
      border-radius: 12px;
      transition: background 0.4s linear;
    }

    button:hover {
      background: rgba(61, 61, 61, 0.1);
    }
  }

  ul {
    height: 100%;
    display: flex;
    padding: 1rem;
    flex-direction: column;

    li {
      list-style-type: none;
      margin: 0.3rem 0;
      font-size: 1.1rem;
      color: #282828;
      cursor: pointer;
      background: transparent;
      transition: background 0.4s linear;
      padding: 0.4rem 0.8rem;
      border-radius: 12px;
    }
    li:hover {
      background: rgba(61, 61, 61, 0.1);
    }

    a {
      text-decoration: none;
      color: #282828;
    }
  }
}
