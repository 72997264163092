.c-logo {
  //   @include Laptops() {
  //     position: fixed;
  //     top: 0;
  //     width: 100%;
  //   }

  //   @include HiDPI() {
  //     position: fixed;
  //     top: 0;
  //     width: 100%;
  //   }
  z-index: 130;

  .logo {
    background: url('./../../images/SUCASA-LOGO.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 190px;
    height: 70px;
  }
}
