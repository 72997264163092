// Logo
@import './cLogo';
@import './cLang';
@import './language';

.custom-nav-full-width {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  height: 8.5vh;

  @include Laptops() {
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  @include HiDPI() {
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .custom-nav {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
