.model-self-driving {
  margin-bottom: 3rem;
  margin-top: 5rem;

  .self-d-heading {
    text-align: center;
    font-weight: 500;
    margin: 0;
  }

  h6 {
    text-align: center;
    margin: 0.5rem 0 0.8rem;
    font-size: 0.9rem;
    font-weight: 500;
  }

  ul {
    padding: 0 1.5rem;

    li {
      color: #4f4f4f;
      margin: 0.8rem 0;
      font-size: 0.9rem;
    }
  }
}

.model-comming-soon {
  margin-bottom: 3rem;

  h5 {
    color: #4f4f4f;
  }

  li {
    margin: 1rem 1rem;
    font-size: 0.9rem;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #4f4f4f;
  }
}

.model-order {
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: 1.55rem;
    font-weight: 500;
    margin: 0 0 1rem;
    color: #282828;

    @include Laptops() {
      font-size: 1.8rem;
    }
  }

  h4 {
    font-weight: 500;
    color: #4f4f4f;
    margin: 0 0 1.2rem;
    font-size: 0.9rem;
  }

  button {
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 22px;
    border: none;
    background-color: rgb(51, 109, 255);
    transition: background 0.4s linear;
    color: #f0f0f0;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.8rem;
  }

  button:hover {
    background-color: rgb(17, 84, 252);
  }
}
