.specs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  padding: 0 1.5rem;

  .spec {
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      display: inline;
    }

    span {
      font-size: 0.8rem;
      font-weight: 400;
      margin-left: 0.1rem;
    }

    h6 {
      font-weight: 400;
      text-align: center;
    }
  }
}
