@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-family: 'Montserrat', sans-serif;
}

html,
body {
  overflow: hidden;
  position: relative;
  height: 100%;
}

button,
div {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div,
button:focus {
  outline: none !important;
}

a {
  text-decoration: none;
  color: unset;
}

// Media querries
@import './media-colors/mediaquerries';

// Components
@import './components/Nav';
@import './components/MobileMenu';
@import './components/DesktopMenu';
@import './components/custom/modelContainer';

// Pages
@import './pages/Homepage';
@import './pages/Model';
@import './pages/NotFound';
