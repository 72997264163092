@mixin BigPhone {
  @media (min-width: 100px) and (max-width: 567px) {
    @content;
  }
}

// 568 - 734
// phone landscapes
@mixin Landscape {
  @media (min-width: 568px) and (max-width: 734px) {
    @content;
  }
}

@mixin Pads {
  @media (min-width: 735px) and (max-width: 1023px) {
    @content;
  }
}

@mixin PadsPro {
  @media (min-width: 1024px) and (max-width: 1279px) {
    @content;
  }
}

@mixin Laptops {
  @media (min-width: 1280px) and (max-width: 1439px) {
    @content;
  }
}

@mixin HiDPI {
  @media (min-width: 1440px) {
    @content;
  }
}
