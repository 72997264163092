/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@font-face {
  font-family: 'Gotham Book';
  src: url('../fonts/Gotham-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Gotham Book', sans-serif;
  /* font-family: 'Montserrat', sans-serif;
  font-family: 'Roboto', sans-serif; */
}

html,
body {
  overflow: hidden;
  position: relative;
  height: 100%;
  background-color: #ffffff;
}

button,
div {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div,
button:focus {
  outline: none !important;
}

a {
  text-decoration: none;
  color: unset;
}


nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack:justify;
          justify-content: space-between;
  padding: 0rem 0.8rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  color: #fff;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  nav {
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  nav {
    max-width: 1800px;
    margin: 0 auto;
  }
}

nav .nav-logo {
  background: url("./../images/SUCASA-LOGOwhite.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 190px;
  height: 70px;
}

nav .nav-main-links {
  display: none;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  nav .nav-main-links {
    display: block;
    margin-left: 10rem;
  }
  nav .nav-main-links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  nav .nav-main-links ul li {
    margin: 0rem 0.4rem;
    font-weight: 500;
    list-style-type: none;
    font-size: 0.9rem;
    cursor: pointer;
    background: transparent;
    -webkit-transition: background 0.4s linear;
    transition: background 0.4s linear;
    padding: 0.4rem 0.8rem;
    border-radius: 12px;
    position: relative;
  }
}

@media (min-width: 1440px) {
  nav .nav-main-links {
    display: block;
    margin-left: 18rem;
  }
  nav .nav-main-links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  nav .nav-main-links ul li {
    margin: 0rem 0.4rem;
    font-weight: 500;
    list-style-type: none;
    font-size: 0.9rem;
    cursor: pointer;
    background: transparent;
    -webkit-transition: background 0.4s linear;
    transition: background 0.4s linear;
    padding: 0.4rem 0.8rem;
    border-radius: 12px;
    position: relative;
  }
}

nav .nav-menu button {
  border: none;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 22px;
  letter-spacing: 0.5px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  color: #000;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  nav .nav-menu button {
    display: none;
  }
}

@media (min-width: 1440px) {
  nav .nav-menu button {
    display: none;
  }
}

nav .nav-user-links-desktop {
  display: none;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  nav .nav-user-links-desktop {
    display: block;
  }
  nav .nav-user-links-desktop ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  nav .nav-user-links-desktop ul li {
    margin: 0rem 0.4rem;
    font-weight: 500;
    list-style-type: none;
    font-size: 0.9rem;
    cursor: pointer;
    background: transparent;
    -webkit-transition: background 0.4s linear;
    transition: background 0.4s linear;
    padding: 0.4rem 0.8rem;
    border-radius: 12px;
    position: relative;
  }
}

@media (min-width: 1440px) {
  nav .nav-user-links-desktop {
    display: block;
  }
  nav .nav-user-links-desktop ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  nav .nav-user-links-desktop ul li {
    margin: 0rem 0.4rem;
    font-weight: 500;
    list-style-type: none;
    font-size: 0.9rem;
    cursor: pointer;
    background: transparent;
    -webkit-transition: background 0.4s linear;
    transition: background 0.4s linear;
    padding: 0.4rem 0.8rem;
    position: relative;
  }
}

nav .nav-user-links-desktop .menu-li {
  background: transparent;
  -webkit-transition: background ease 0.5s;
  transition: background ease 0.5s;
  border-radius: 19px;
}

nav .nav-user-links-desktop .menu-li:hover {
  background: rgba(255, 255, 255, 0.4);
}

.m-nav {
  position: absolute;
  top: 0;
  left: 0;
}

.dropback {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 19px;
}

.dropback3 {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(237, 237, 237, 0.6);
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 19px;
}

.blur-container {
  height: 100vh;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  width: 100%;
  background-color: rgba(61, 61, 61, 0.2);
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .blur-container {
    display: none;
  }
}

@media (min-width: 1440px) {
  .blur-container {
    display: none;
  }
}

.mobile-menu {
  height: 100vh;
  z-index: 25;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  background-color: #fbfbfb;
}

.mobile-menu .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1.5rem;
}

.mobile-menu .button button {
  font-size: 1.2rem;
  border: none;
  background: transparent;
  font-weight: 400;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  border-radius: 50%;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
}

.mobile-menu .button button:hover {
  background: rgba(61, 61, 61, 0.1);
}

.mobile-menu ul {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mobile-menu ul li {
  list-style-type: none;
  margin: 0.3rem 0;
  font-size: 1rem;
  color: #282828;
  cursor: pointer;
  background: transparent;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
  padding: 0.6rem 0.8rem;
  border-radius: 12px;
}

.mobile-menu ul li:hover {
  background: rgba(61, 61, 61, 0.1);
}

.mobile-menu ul a {
  text-decoration: none;
  color: #282828;
}

.blur-container-desktop {
  height: 100vh;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  width: 100%;
  background-color: rgba(61, 61, 61, 0.2);
  display: none;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .blur-container-desktop {
    display: block;
  }
}

@media (min-width: 1440px) {
  .blur-container-desktop {
    display: block;
  }
}

.mobile-menu {
  height: 100vh;
  z-index: 25;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  background-color: #fbfbfb;
}

.mobile-menu .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1.5rem;
}

.mobile-menu .button button {
  font-size: 1.2rem;
  border: none;
  background: transparent;
  font-weight: 400;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  border-radius: 12px;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
}

.mobile-menu .button button:hover {
  background: rgba(61, 61, 61, 0.1);
}

.mobile-menu ul {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mobile-menu ul li {
  list-style-type: none;
  margin: 0.3rem 0;
  font-size: 1.1rem;
  color: #282828;
  cursor: pointer;
  background: transparent;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
  padding: 0.4rem 0.8rem;
  border-radius: 12px;
}

.mobile-menu ul li:hover {
  background: rgba(61, 61, 61, 0.1);
}

.mobile-menu ul a {
  text-decoration: none;
  color: #282828;
}

.c-logo {
  z-index: 130;
}

.c-logo .logo {
  background: url("./../images/SUCASA-LOGO.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 190px;
  height: 70px;
}

@media (max-width: 767px) {
  .c-logo .logo {
  width: 150px;
  height: 50px;}
}

.c-lang {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (auto)[2];
      grid-template-columns: repeat(2, auto);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 0.4rem;
  border-radius: 19px;
  cursor: pointer;
  padding: 0.6rem 0.7rem;
  background: transparent;
  -webkit-transition: background 0.5s ease;
  transition: background 0.5s ease;
  margin: 0.5rem;
  z-index: 130;
}

.c-lang h1 {
  font-size: 0.9rem;
  background: none;
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.c-lang:hover {
  background: rgba(240, 240, 240, 0.9);
}

.language-container {
  height: 100vh;
  background: rgba(172, 172, 172, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 120;
}

.language-container .language {
  background: #fff;
  overflow-y: scroll;
  z-index: 132;
  padding-top: 3rem;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .language-container .language {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (40%)[2];
        grid-template-columns: repeat(2, 40%);
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .language-container .language {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (40%)[2];
        grid-template-columns: repeat(2, 40%);
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .language-container .language {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (40%)[2];
        grid-template-columns: repeat(2, 40%);
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media (min-width: 1440px) {
  .language-container .language {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (40%)[2];
        grid-template-columns: repeat(2, 40%);
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

.language-container .language .language-items {
  width: 70%;
  margin: 2rem auto;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .language-container .language .language-items {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .language-container .language .language-items {
    width: 400px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .language-container .language .language-items {
    width: 400px;
  }
}

@media (min-width: 1440px) {
  .language-container .language .language-items {
    width: 400px;
  }
}

.language-container .language .language-items h2 {
  margin: 0;
  text-align: left;
}

.language-container .language .language-items .language-items-parent {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (50%)[2];
      grid-template-columns: repeat(2, 50%);
  grid-gap: 0.5rem;
}

.language-container .language .language-items .language-items-parent .language-items-container {
  margin: 0.5rem 0rem;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  background: transparent;
  -webkit-transition: background 0.5s ease-in;
  transition: background 0.5s ease-in;
  cursor: pointer;
}

.language-container .language .language-items .language-items-parent .language-items-container h5 {
  margin: 0.5rem 0;
  cursor: pointer;
  font-weight: 400;
  color: #414141;
}

.language-container .language .language-items .language-items-parent .language-items-container:hover {
  background: #f0f0f0;
}

.language-container .language .language-items .language-items-parent .active {
  background: #f0f0f0;
}

.custom-nav-full-width {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  height: 8.5vh;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .custom-nav-full-width {
    position: fixed;
    top: 0;
    z-index: 1000;
  }
}

@media (min-width: 1440px) {
  .custom-nav-full-width {
    position: fixed;
    top: 0;
    z-index: 1000;
  }
}

.custom-nav-full-width .custom-nav {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.loader {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: -ms-grid;
  display: grid;
  place-content: center;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loader img {
  width: 500px;
  height: 200px;
}

@media (max-width: 767px) {
  .loader img {
    width: 200px;
    height: 60px;
  }
}

.name {
  width: 80%;
  margin: 1rem auto;
  text-align: center;
}

.name h1 {
  font-weight: 500;
  font-size: 1.8rem;
}

.name h4 {
  font-weight: 400;
  color: #a0a0a0;
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.price-selector {
  background: #f0f0f0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 19px;
  padding: 0.3rem 0.3rem;
}

.price-selector .price-btn {
  position: relative;
  padding: 0.4rem 2.4rem;
  z-index: 113;
}

.price-selector .price-btn h5 {
  font-weight: 500;
  font-size: 0.8rem;
  color: #505050;
  z-index: 112;
  text-align: center;
}

.price-selector .price-btn .dropback {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.specs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 2rem 0;
  padding: 0 1.5rem;
}

.specs .spec h2 {
  font-size: 1.5rem;
  font-weight: 500;
  display: inline;
}

.specs .spec span {
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 0.1rem;
}

.specs .spec h6 {
  font-weight: 400;
  text-align: center;
}

h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500;
}

.paint-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
}

.paint-selector .color-border {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: -ms-grid;
  display: grid;
  place-content: center;
  background: transparent;
  border: 2px solid transparent;
  -webkit-transition: border 0.6s linear;
  transition: border 0.6s linear;
}

.paint-selector .color-border .color {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-position: center;
  background-size: cover;
}

.paint-selector .color-border .color img {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  display: block;
}

.rowlisting img{
  width: calc(33.33% - 10px);
  margin-bottom: 20px;
}

.paint-selector .show {
  border: 2px solid blue;
}

.paint-info {
  text-align: center;
  margin: 1.5rem 0;
}

.paint-info h3 {
  font-weight: 500;
  font-size: 1rem;
}

.paint-info h6 {
  font-weight: 500;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.wheel-selector .wheel h4 {
  font-size: 0.9rem;
  font-weight: 500;
}

.wheel-selector .wheel .motor {
  border: 2px solid #949494;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.5rem 0;
  padding: 1rem 1rem;
  border-radius: 30px;
  -webkit-transition: border 0.5s ease;
  transition: border 0.5s ease;
  cursor: pointer;
}

.wheel-selector .wheel .motor h5 {
  font-size: 0.9rem;
  font-weight: 400;
}

.wheel-selector .wheel .checked {
  border: 2px solid #295bff;
}

.wheel-selector .learn-more {
  margin: 1.4rem 0;
}

.wheel-selector .learn-more h6 {
  font-size: 0.8rem;
  font-weight: 400;
  color: #282828;
}

.wheel-selector .learn-more h6 span {
  border-bottom: 1px solid #787878;
  color: #787878;
  font-weight: 300;
  cursor: pointer;
}

.price-custom p {
  text-align: center;
  font-size: 0.8rem;
  color: #949494;
  margin: 1rem 0;
  line-height: 1.3rem;
}

.price-custom button {
  background: #f0f0f0;
  color: #000;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 2rem auto;
  display: block;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
}

h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500;
}

.rim-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.rim-selector .rim-border {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: -ms-grid;
  display: grid;
  place-content: center;
  background: transparent;
  border: 2px solid transparent;
  -webkit-transition: border 0.6s linear;
  transition: border 0.6s linear;
}

.rim-selector .rim-border .rim {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.rim-selector .rim-border .eighteen {
  background: url("./../images/bgs/models/felne/felnanew1.jpg");
  background-position: center;
  background-size: cover;
}

.rim-selector .rim-border .nineteen {
  background: url("./../images/bgs/models/felne/felnanew2.jpg");
  background-position: center;
  background-size: cover;
}

.rim-selector .show {
  border: 2px solid blue;
}

.rims-info {
  text-align: center;
  margin: 1.5rem 0;
}

.rims-info h3 {
  font-weight: 500;
  font-size: 1rem;
}

.rims-info h6 {
  font-weight: 500;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.model-self-driving {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.model-self-driving .self-d-heading {
  text-align: center;
  font-weight: 500;
  margin: 0;
}

.model-self-driving h6 {
  text-align: center;
  margin: 0.5rem 0 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.model-self-driving ul {
  padding: 0 1.5rem;
}

.model-self-driving ul li {
  color: #4f4f4f;
  margin: 0.8rem 0;
  font-size: 0.9rem;
}

.model-comming-soon {
  margin-bottom: 3rem;
}

.model-comming-soon h5 {
  color: #4f4f4f;
}

.model-comming-soon li {
  margin: 1rem 1rem;
  font-size: 0.9rem;
}

.model-comming-soon p {
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #4f4f4f;
}

.model-order {
  text-align: center;
  margin-bottom: 4rem;
}

.model-order h1 {
  font-size: 1.55rem;
  font-weight: 500;
  margin: 0 0 1rem;
  color: #282828;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-order h1 {
    font-size: 1.8rem;
  }
}

.model-order h4 {
  font-weight: 500;
  color: #4f4f4f;
  margin: 0 0 1.2rem;
  font-size: 0.9rem;
}

.model-order button {
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 22px;
  border: none;
  background-color: #336dff;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
  color: #f0f0f0;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8rem;
}

.model-order button:hover {
  background-color: #1154fc;
}

h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500;
  margin-top: 2rem;
}

.interior-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.interior-selector .interior-border {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: -ms-grid;
  display: grid;
  place-content: center;
  background: transparent;
  border: 2px solid transparent;
  -webkit-transition: border 0.6s linear;
  transition: border 0.6s linear;
}

.interior-selector .interior-border .interior {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-color: #000;
}

/* .interior-selector .interior-border .black {
  background: url("./../images/bgs/models/interior/interior2.jpeg");
  background-position: center;
  background-size: cover;
} */

/* .interior-selector .interior-border .red {
  background: url("./../images/bgs/models/interior/interior1.jpeg");
  background-position: center;
  background-size: cover;
} */

.interior-selector .show {
  border: 2px solid blue;
}

.interior-info {
  text-align: center;
  margin: 1.5rem 0;
  margin-bottom: 5rem;
}

.interior-info h3 {
  font-weight: 500;
  font-size: 1rem;
}

.interior-info h6 {
  font-weight: 500;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.final-price {
  margin-top: 1rem;
}

.final-price .final-price-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.final-price .final-price-container h6 {
  margin: 0.8rem 0rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: #444444;
}

.final-price .final-price-container .final-price-left {
  width: 50%;
}

.final-price .final-price-container .final-price-right {
  width: 50%;
  text-align: right;
}

.final-price .final-price-container p {
  font-size: 0.9rem;
  color: #686868;
  margin: 0.5rem 0;
}

.selected-model-specs {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.selected-model-specs div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0.8rem 0;
}

.selected-model-specs div h4 {
  font-weight: 400;
  font-size: 0.9rem;
}

.cash-payment {
  margin: 1.5rem 0;
}

.cash-payment p {
  font-size: 0.9rem;
  color: #797979;
  line-height: 1.2rem;
  margin: 0.2rem 0;
}

.cash-payment div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0.8rem 0;
}

.cash-payment div h3 {
  font-weight: 500;
  font-size: 1rem;
}

.cash-payment div h4 {
  font-weight: 400;
  font-size: 0.9rem;
}

.model-info {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 0.5rem;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-info {
    margin-top: 5rem;
  }
}

@media (min-width: 1440px) {
  .model-info {
    margin-top: 5rem;
    max-width: 400px;
  }
}

.model-info .order-btn {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  display: block;
  border-radius: 22px;
  border: 2px solid black;
  background: #fff;
  color: black;
  font-size: 1rem;
  outline: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.model-info .order-btn:hover {
  background: #282828;
  color: #fff;
}

.your-model-h2 {
  margin: 0;
}

.your-model-container {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.your-model-container .your-model-right {
  text-align: right;
}

.your-model-container h6 {
  margin: 0.8rem 0rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #686868;
}

.checkout {
  padding: 2rem 0;
  margin-top: 2rem;
}

.checkout h1 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #282828;
}

.checkout .checkout-container {
  padding: 1rem 0;
}

.checkout .checkout-container .c-c-box {
  padding: 1rem 0;
}

.checkout .checkout-container .c-c-box label {
  display: block;
  width: 100%;
  font-size: 0.9rem;
  color: #282828;
  font-weight: 500;
  padding: 0 0 0.5rem 1rem;
}

.checkout .checkout-container .c-c-box input {
  width: 100%;
  padding: 0.6rem 1rem;
  border-radius: 22px;
  background: whitesmoke;
  border: 1px solid transparent;
  outline: none;
  -webkit-transition: border 0.3s ease;
  transition: border 0.3s ease;
  font-weight: 500;
}

.checkout .checkout-container .c-c-box input:focus {
  border: 1px solid #cecece;
}

.checkout .checkout-container .c-c-box h5 {
  padding: 1rem 0 1rem 1rem;
  color: #282828;
  font-weight: 500;
}

.checkout .checkout-container .c-c-box .phone-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkout .checkout-container .c-c-box .phone-box select {
  width: 30%;
  padding: 0.6rem 1rem;
  border-radius: 22px;
  background: whitesmoke;
  border: 1px solid transparent;
  outline: none;
  -webkit-transition: border 0.3s ease;
  transition: border 0.3s ease;
  font-weight: 500;
  margin-right: 2rem;
}

.checkout .checkout-container .c-c-box .phone-box input {
  width: 70%;
  padding: 0.6rem 1rem;
  border-radius: 22px;
  background: whitesmoke;
  border: 1px solid transparent;
  outline: none;
  -webkit-transition: border 0.3s ease;
  transition: border 0.3s ease;
  font-weight: 500;
}

.checkout .checkout-container .c-c-box .phone-box input::-webkit-input-placeholder {
  color: #cecece;
}

.checkout .checkout-container .c-c-box .phone-box input:-ms-input-placeholder {
  color: #cecece;
}

.checkout .checkout-container .c-c-box .phone-box input::-ms-input-placeholder {
  color: #cecece;
}

.checkout .checkout-container .c-c-box .phone-box input::placeholder {
  color: #cecece;
}

.checkout .checkout-container .c-c-box .agree-head {
  padding: 2rem 1rem;
  color: #383838;
  font-weight: 300;
}

.checkout .checkout-container .d-tdy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem 0;
}

.checkout .checkout-container .d-tdy h4 {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 0.9rem;
  color: #383838;
}

.checkout .checkout-container h5 {
  color: #4d4d4d;
  font-weight: 300;
  padding-bottom: 3rem;
}

.checkout .checkout-container h5 span {
  color: #155fff;
  font-weight: 500;
}

.checkout button {
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 22px;
  border: none;
  background-color: #336dff;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
  color: #f0f0f0;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8rem;
}

.checkout button:hover {
  background-color: #1154fc;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-container-large {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1440px) {
  .model-container-large {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.model-container-large .slider {
  display: none;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-container-large .slider {
    display: block;
    width: 70vw;
    height: 100vh;
    position: relative;
  }
  .model-container-large .slider img {
    height: 100%;
    width: inherit;
    -o-object-fit: contain;
       object-fit: contain;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .model-container-large .slider .slider-btn-container {
    position: fixed;
    top: 0;
    left: 0;
    height: inherit;
    width: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 2rem;
  }
  .model-container-large .slider .interior {
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media (min-width: 1440px) {
  .model-container-large .slider {
    display: block;
    width: 70%;
    height: 100vh;
    position: relative;
  }
  .model-container-large .slider img {
    height: inherit;
    width: inherit;
    -o-object-fit: contain;
       object-fit: contain;
    position: fixed;
    top: 0;
    left: 0;
  }
  .model-container-large .slider .slider-btn-container {
    position: fixed;
    top: 45%;
    height: 100px;
    width: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 2rem;
  }
  .model-container-large .slider .slider-btn-container button {
    border-radius: 50%;
    border: none;
    outline: none;
    display: -ms-grid;
    display: grid;
    place-content: center;
    font-size: 1.8rem;
    background: #f0f0f0;
    cursor: pointer;
  }
  .model-container-large .slider .interior {
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.model-box {
  position: relative;
  width: 100%;
  height: 100vh;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-box {
    width: 30vw;
    height: 100vh;
  }
}

@media (min-width: 1440px) {
  .model-box {
    width: 30%;
    height: 100vh;
  }
}

.model-box img {
  width: inherit;
  height: auto;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-box img {
    display: none;
  }
}

@media (min-width: 1440px) {
  .model-box img {
    display: none;
  }
}

.p-c {
  height: 100vh;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  -webkit-scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
}

.p-c .overlay-r {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: -100vh;
}

.p-c .overlay-r .overlay-m {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  margin-top: -100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  text-align: center;
}

.p-c .overlay-r .overlay-m h1 {
  padding-top: 20px;
  font-size: 3rem;
  font-weight: 400;
}

@media (max-width: 767px) {
  .p-c .overlay-r .overlay-m h1 {
    font-size: 2rem;
  }
}

.p-c .overlay-r .overlay-m h5 {
  font-size: 1.1rem;
  font-weight: 500;
}

@media (max-width: 767px) {
  .p-c .overlay-r .overlay-m h5 {
    font-size: 0.8rem;
  }
}

.p-c .overlay-r .overlay-m .overlayy-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 90%;
  padding-top: 10rem;
}

@media (min-width: 568px) and (max-width: 734px) {
  .p-c .overlay-r .overlay-m .overlayy-btns {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (min-width: 735px) and (max-width: 1023px) {
  .p-c .overlay-r .overlay-m .overlayy-btns {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .p-c .overlay-r .overlay-m .overlayy-btns {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .p-c .overlay-r .overlay-m .overlayy-btns {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (min-width: 1440px) {
  .p-c .overlay-r .overlay-m .overlayy-btns {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.p-c .overlay-r .overlay-m .overlayy-btns button {
  margin: 0.5rem 0rem;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0.8rem 0;
  font-size: 0.8rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

@media (min-width: 568px) and (max-width: 734px) {
  .p-c .overlay-r .overlay-m .overlayy-btns button {
    width: 250px;
    font-size: 0.8rem;
    margin: 0;
  }
}

@media (min-width: 735px) and (max-width: 1023px) {
  .p-c .overlay-r .overlay-m .overlayy-btns button {
    width: 250px;
    margin: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .p-c .overlay-r .overlay-m .overlayy-btns button {
    width: 250px;
    margin: 0;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .p-c .overlay-r .overlay-m .overlayy-btns button {
    width: 250px;
    margin: 0;
  }
}

@media (min-width: 1440px) {
  .p-c .overlay-r .overlay-m .overlayy-btns button {
    width: 250px;
    margin: 0;
  }
}

.p-c .overlay-r .overlay-m .overlayy-btns .primary-btn {
  background: rgba(0, 0, 0, 0.7);
  color: #cecece;
  padding: 15px;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
}

.p-c .overlay-r .overlay-m .overlayy-btns .primary-btn:hover {
  background: rgba(0, 0, 0, 0.9);
  padding: 15px;
  color: #cecece;
}

.p-c .overlay-r .overlay-m .overlayy-btns .secondary-btn {
  background: rgba(255, 255, 255, 0.7);
  color: #282828;
  padding: 15px;
  -webkit-transition: background 0.4s linear;
  transition: background 0.4s linear;
}

.p-c .overlay-r .overlay-m .overlayy-btns .secondary-btn:hover {
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.p-c .p-cc {
  height: 100vh;
  scroll-snap-align: start;
  -webkit-scroll-snap-align: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5rem 0;
  overflow-x: hidden;
}

.p-c .p-cc .p-c-child {
  width: 500px;
  height: 1px;
  background: transparent;
}

.p-c .fourbedprem {
  background: url("./../images/bgs/fourbedprem/4BED_SideShot.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.p-c .fourbedext {
  background: url("./../images/bgs/fourbedextended/4BED_Front01.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .p-c .fourbedext {
    background: url("./../images/bgs/fourbedextended/4BED_Front01.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .p-c .fourbedext {
    background: url("./../images/bgs/fourbedextended/4BED_Front01.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1440px) {
  .p-c .fourbedext {
    background: url("./../images/bgs/fourbedextended/4BED_Front01.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.p-c .fourbedstd {
  background: url("./../images/bgs/fourbedstandard/4BED_Front02.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .p-c .fourbedstd {
    background: url("./../images/bgs/fourbedstandard/4BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .p-c .fourbedstd {
    background: url("./../images/bgs/fourbedstandard/4BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .p-c .fourbedstd {
    background: url("./../images/bgs/fourbedstandard/4BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1440px) {
  .p-c .fourbedstd {
    background: url("./../images/bgs/fourbedstandard/4BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}


.p-c .threebedext {
  background: url("./../images/bgs/threebedextended/3BED_Front03.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .p-c .threebedext {
    background: url("./../images/bgs/threebedextended/3BED_Front03.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .p-c .threebedext {
    background: url("./../images/bgs/threebedextended/3BED_Front03.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .p-c .threebedext {
    background: url("./../images/bgs/threebedextended/3BED_Front03.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1440px) {
  .p-c .threebedext {
    background: url("./../images/bgs/threebedextended/3BED_Front03.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}


.p-c .threebedstd {
  background: url("./../images/bgs/threebedstandard/3BED_Front02.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .p-c .threebedstd {
    background: url("./../images/bgs/threebedstandard/3BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .p-c .threebedstd {
    background: url("./../images/bgs/threebedstandard/3BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .p-c .threebedstd {
    background: url("./../images/bgs/threebedstandard/3BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1440px) {
  .p-c .threebedstd {
    background: url("./../images/bgs/threebedstandard/3BED_Front02.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}


.p-c .twobed {
  background: url("./../images/bgs/twobedroom/2BED_Front02_ps.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .p-c .twobed {
    background: url("./../images/bgs/twobedroom/2BED_Front02_ps.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .p-c .twobed {
    background: url("./../images/bgs/twobedroom/2BED_Front02_ps.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .p-c .twobed {
    background: url("./../images/bgs/twobedroom/2BED_Front02_ps.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1440px) {
  .p-c .twobed {
    background: url("./../images/bgs/twobedroom/2BED_Front02_ps.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

}

.crs-parent {
  height: 100vh;
  width: 100%;
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.crs-parent .crs-container {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -ms-grid-columns: (auto)[5];
      grid-template-columns: repeat(5, auto);
  height: 50vh;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
}

.crs-parent .crs-container div {
  height: inherit;
  scroll-snap-align: start;
}

.crs-parent .crs-container .crs-em {
  width: 400px;
}

.crs-parent .crs-container .crs {
  width: 850px;
}

.crs-parent .crs-container .crs-active {
  background: #eeeeee;
}

.mdl-desc-cont {
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10rem 0 5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mdl-desc-cont .mdl-head {
  text-align: center;
  font-weight: 500;
  font-size: 2.2rem;
  color: #fff;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .mdl-desc-cont .mdl-head {
    font-size: 2.7rem;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont .mdl-head {
    font-size: 2.7rem;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont .mdl-head {
    font-size: 2.7rem;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont .mdl-desc {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont .mdl-desc {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 2rem;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.mdl-desc-cont .mdl-desc .mdl-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2rem 0;
  color: #fff;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont .mdl-desc .mdl-cont {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[3];
        grid-template-columns: repeat(3, auto);
    grid-gap: 2rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

/* @media (min-width: 1440px) {
  .mdl-desc-cont .mdl-desc .mdl-cont {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[3];
        grid-template-columns: repeat(3, auto);
    grid-gap: 2rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
} */

.mdl-desc-cont .mdl-desc .mdl-cont .mdl-spec {
  text-align: center;
  margin: 0 1rem;
}

.mdl-desc-cont .mdl-desc .mdl-cont .mdl-spec .mdl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-bottom: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.mdl-desc-cont .mdl-desc .mdl-cont .mdl-spec .mdl h3 {
  margin-right: 0.1rem;
  font-weight: 500;
  font-size: 1.2rem;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .mdl-desc-cont .mdl-desc .mdl-cont .mdl-spec .mdl h3 {
    font-size: 1.2rem;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont .mdl-desc .mdl-cont .mdl-spec .mdl h3 {
    font-size: 1.2rem;
  }
}

.mdl-desc-cont .mdl-desc .mdl-cont .mdl-spec .mdl span {
  font-size: 0.9rem;
}

.mdl-desc-cont .mdl-desc button {
  width: 100%;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 0.6rem 0;
  border-radius: 22px;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont .mdl-desc button {
    width: 220px;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont .mdl-desc button {
    width: 220px;
  }
}

.mdl-desc-cont .mdl-desc button:hover {
  background: #fff;
  color: #282828;
}

.mdl-desc-cont2 {
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5rem 0 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mdl-desc-cont2 .mdl-head {
  text-align: center;
  font-weight: 500;
  font-size: 2.2rem;
  color: #fff;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .mdl-desc-cont2 .mdl-head {
    font-size: 2.7rem;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont2 .mdl-head {
    font-size: 2.7rem;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont2 .mdl-head {
    font-size: 2.7rem;
  }
}

.mdl-desc-cont2 .mdl-desc .mdl-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2rem 0;
  color: #fff;
}

.mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec {
  text-align: center;
  margin: 0 1rem;
}

.mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec .mdl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-bottom: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec .mdl h3 {
  margin-right: 0.1rem;
  font-weight: 500;
  font-size: 1.5rem;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec .mdl h3 {
    font-size: 2rem;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec .mdl h3 {
    font-size: 2rem;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec .mdl h3 {
    font-size: 2rem;
  }
}

.mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec .mdl span {
  font-size: 0.9rem;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec h6 {
    display: none;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec h6 {
    display: none;
  }
}

.mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec h5 {
  display: none;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec h5 {
    display: block;
    width: 300px;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
  }
}

@media (min-width: 1440px) {
  .mdl-desc-cont2 .mdl-desc .mdl-cont .mdl-spec h5 {
    display: block;
    width: 300px;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
  }
}

.mdl-desc-cont2 .mdl-desc button {
  width: 100%;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 0.6rem 0;
  border-radius: 22px;
  font-weight: 500;
  cursor: pointer;
}

.model-page .model-banner {
  display: -ms-grid;
  display: grid;
  place-content: center;
  height: 100vh;
}

.model-page .model-a3 {
  background: url("./../images/bgs/twobedroom/2BED_Front02full.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .model-a3 {
    background: url("./../images/bgs/twobedroom/2BED_Front02full.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .model-a3 {
    background: url("./../images/bgs/twobedroom/2BED_Front02full.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .model-a3 {
    background: url("./../images/bgs/twobedroom/2BED_Front02full.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .model-page .model-a3 {
    background: url("./../images/bgs/twobedroom/2BED_Front02full.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
}
/* 
.model-page .model-s3 {
  background: url("./../images/bgs/a3/a3bg-3.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .model-s3 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .model-s3 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .model-s3 {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .model-s3 {
    height: 75vh;
  }
} */

.model-page .livingroomtwo {
  background: url("./../images/bgs/twobedroom/2BEDV2_LVNRM_LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .livingroomtwo {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .livingroomtwo {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .livingroomtwo {
    height: 70vh;
  }
}

@media (min-width: 1440px) {
  .model-page .livingroomtwo {
    height: 70vh;
  }
}

.model-page .twobedroomensuite1 {
  background: url("./../images/bgs/twobedroom/2BED_MT_BED_01_LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .bedroomensuite1 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .bedroomensuite1 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .bedroomensuite1 {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .bedroomensuite1 {
    height: 75vh;
  }
}

.model-page .twobedroomensuite2 {
  background: url("./../images/bgs/twobedroom/2BED_BED2_02_LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .bedroomensuite2 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .bedroomensuite2 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .bedroomensuite2 {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .bedroomensuite2 {
    height: 75vh;
  }
}



.model-page .twovisitorswashroom {
  background: url("./../images/bgs/twobedroom/2BED_MT_BATH_LG.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .twovisitorswashroom {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .twovisitorswashroom {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .twovisitorswashroom {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .twovisitorswashroom {
    height: 75vh;
  }
}



.model-page .interior2-a3 {
  background: url("./../images/bgs/twobedroom/2BED_irongated.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

.model-page .interior3-a3 {
  background: url("./../images/bgs/twobedroom/2BEd_carlot.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

.model-page .interior4-a3 {
  background: url("./../images/bgs/twobedroom/2BED_compund.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

.model-page .interior5-a3 {
  background: url("./../images/bgs/twobedroom/2BED_entry.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

.model-page .model-intro-text {
  text-align: left;
  padding: 1rem;
  margin: 2rem auto 0;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .model-intro-text {
    padding: 1rem 2.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .model-intro-text {
    padding: 1rem 2.5rem;
  }
}

.model-page .model-intro-text h5 {
  font-weight: 300;
  font-size: 1.2rem;
  color: #535353;
}

.model-page .model-intro-text h2 {
  text-align: left;
  margin: 0;
  font-weight: 500;
}

.model-page .model-intro-text div {
  margin: 1.5rem 0;
}

.model-page .model-intro-text div h3 {
  font-weight: 500;
  font-size: 1rem;
}

.model-page .model-intro-text div h3 span {
  background: #282828;
  color: #fff;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.7rem;
}

.model-page .model-intro-text div p {
  color: #535353;
  line-height: 1.5rem;
}

.model-page .model-intro-text div img {
  width: 100%;
  height: auto;
}

.model-page .model-intro-text div ul {
  width: 70%;
  margin: -2rem auto 0;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .model-intro-text div ul {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .model-page .model-intro-text div ul {
    width: 100%;
  }
}

.model-page .model-intro-text div ul li {
  margin: 1.5rem 0;
  list-style: none;
}

.model-page .model-intro-text div ul li h3 {
  margin-bottom: 0.5rem;
}

.model-page .model-intro-text div button {
  width: 70%;
  margin: 2rem auto;
  display: block;
  background: transparent;
  border: 2px solid #000;
  color: #000;
  padding: 0.6rem 0;
  border-radius: 22px;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.model-page .model-intro-text div button:hover {
  color: #fff;
  background: #282828;
}

.model-page .model-q5 {
  background: url("./../images/bgs/threebedstandard/3BED_home.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.model-page .model-q5four {
  background: url("./../images/bgs/threebedextended/3bedexts.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}


.model-page .model-fourstd {
  background: url("./../images/bgs/fourbedstandard/4BED_Front02mainn.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.model-page .model-fourstd2 {
  background: url("./../images/bgs/fourbedstandard/4BED_Front02ext.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.model-page .model-sq5 {
  background: url("./../images/bgs/threebedstandard/3BEDv2_KTCHN02LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .model-sq5 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .model-sq5 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .model-sq5 {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .model-sq5 {
    height: 75vh;
  }
}


.model-page .model-stdfour {
  background: url("./../images/bgs/fourbedstandard/4bedkitchen.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .model-stdfour {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .model-stdfour {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .model-stdfour {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .model-stdfour {
    height: 75vh;
  }
}


.model-page .rear-q5 {
  background: url("./../images/bgs/threebedstandard/3BEDv2_LVSPC0002LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .rear-q5 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .rear-q5 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .rear-q5 {
    height: 70vh;
  }
}

@media (min-width: 1440px) {
  .model-page .rear-q5 {
    height: 70vh;
  }
}


.model-page .fourbedroom2 {
  background: url("./../images/bgs/fourbedstandard/4BEDstd.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .fourbedroom2 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .fourbedroom2 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .fourbedroom2 {
    height: 70vh;
  }
}

@media (min-width: 1440px) {
  .model-page .fourbedroom2 {
    height: 70vh;
  }
}


.model-page .interior-q5 {
  background: url("./../images/bgs/threebedstandard/3BEDv2_1BDRMBATHS0001LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior-q5 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior-q5 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior-q5 {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior-q5 {
    height: 75vh;
  }
}

.model-page .vsbathroom {
  background: url("./../images/bgs/fourbedstandard/4BEDvstbathroom.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .vsbathroom {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .vsbathroom {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .vsbathroom {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .vsbathroom {
    height: 75vh;
  }
}


.model-page .interior-stairs {
  background: url("./../images/bgs/threebedstandard/3BEDv2_1STFLAREA0003LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior-stairs {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior-stairs {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior-stairs {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior-stairs {
    height: 75vh;
  }
}


.model-page .interior-twostairs {
  background: url("./../images/bgs/fourbedstandard/4bed1stsecond.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior-twostairs {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior-twostairs {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior-twostairs {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior-twostairs {
    height: 75vh;
  }
}



.model-page .interior-outhouse {
  background: url("./../images/bgs/threebedstandard/__3BED_InsideComp.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior-outhouse {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior-outhouse {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior-outhouse {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior-outhouse {
    height: 75vh;
  }
}


.model-page .interior-outhouse2 {
  background: url("./../images/bgs/fourbedstandard/4bedouthouse.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior-outhouse2 {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior-outhouse2 {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior-outhouse2 {
    height: 75vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior-outhouse2 {
    height: 75vh;
  }
}



.model-page .interior2-q8 {
  background: url("./../images/bgs/threebedstandard/__3BED_SideShot.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

.model-page .interiorfour22-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BED_SideShot.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

.model-page .interior3-q8 {
  background: url("./../images/bgs/threebedstandard/__3BED_carlot.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior3-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior3-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior3-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior3-q8 {
    height: 60vh;
  }
}

.model-page .interiormajor-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BED_CompoundShot.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiormajor-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiormajor-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiormajor-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiormajor-q8 {
    height: 60vh;
  }
}



.model-page .interiorfour-q8 {
  background: url("./../images/bgs/fourbedstandard/4BEDensuite1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiorfour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiorfour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiorfour-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiorfour-q8 {
    height: 60vh;
  }
}

.model-page .interiorfour2-q8 {
  background: url("./../images/bgs/fourbedstandard/4BED4thsecond.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiorfour2-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiorfour2-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiorfour2-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiorfour2-q8 {
    height: 60vh;
  }
}


.model-page .interiorfour3-q8 {
  background: url("./../images/bgs/fourbedstandard/4BEDensuite2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiorfour3-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiorfour3-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiorfour3-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiorfour3-q8 {
    height: 60vh;
  }
}


.model-page .interiorfour4-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BEDexecutiveensuit.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiorfour4-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiorfour4-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiorfour4-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiorfour4-q8 {
    height: 60vh;
  }
}

.model-page .interior4-q8 {
  background: url("./../images/bgs/threebedstandard/__3BED_compound.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior4-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior4-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior4-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior4-q8 {
    height: 60vh;
  }
}

.model-page .interior4four-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BED_Aerial.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior4four-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior4four-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior4four-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior4four-q8 {
    height: 60vh;
  }
}

.model-page .interior5-q8 {
  background: url("./../images/bgs/threebedstandard/__3BED_entry2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior5-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior5-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior5-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior5-q8 {
    height: 60vh;
  }
}

.model-page .interior5four-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BEDCloseAerial.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior5four-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior5four-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior5four-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior5four-q8 {
    height: 60vh;
  }
}


.model-page .interior6a-q8 {
  background: url("./../images/bgs/threebedstandard/__3BED_Balcony.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior6a-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior6a-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior6a-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior6a-q8 {
    height: 60vh;
  }
}

.model-page .interior6afour-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BED_MasBalc.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior6afour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior6afour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior6afour-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior6afour-q8 {
    height: 60vh;
  }
}

.model-page .interior6afour2-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BED_MastBalc02.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior6afour2-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior6afour2-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior6afour2-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior6afour2-q8 {
    height: 60vh;
  }
}

.model-page .interior7a-q8 {
  background: url("./../images/bgs/threebedstandard/__3BED_Aerial02.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior7a-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior7a-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior7a-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior7a-q8 {
    height: 60vh;
  }
}

.model-page .interior7afour-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BED_Aerial.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior7afour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior7afour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior7afour-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior7afour-q8 {
    height: 60vh;
  }
}

.model-page .interior8a-q8 {
  background: url("./../images/bgs/threebedstandard/__3BED_AerialTop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior8a-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior8a-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior8a-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior8a-q8 {
    height: 60vh;
  }
}

.model-page .interior8afour-q8 {
  background: url("./../images/bgs/fourbedstandard/__4BED_SideWalkway02.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interior8afour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interior8afour-q8 {
    height: 50vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interior8afour-q8 {
    height: 60vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interior8afour-q8 {
    height: 60vh;
  }
}


.model-page .black {
  background: black;
  color: #fff;
  margin: 0rem 0rem;
  padding: 2rem 1rem;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .black {
    padding: 2rem 2.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .black {
    padding: 2rem 2.5rem;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .black {
    padding: 2rem 5rem;
  }
}

@media (min-width: 1440px) {
  .model-page .black {
    padding: 2rem 5rem;
  }
}

.model-page .black h2 span {
  font-weight: 300;
}

.model-page .black p {
  color: #d6d6d6;
}

.model-page .black div p {
  color: #d6d6d6;
}

.model-page .black img {
  width: 100%;
  height: auto;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .black .specs-heads {
    width: 50%;
    margin: 2rem auto;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .black .specs-heads {
    width: 50%;
    margin: 2rem auto;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .black .specs-heads {
    width: 50%;
    margin: 2rem auto;
  }
}

@media (min-width: 1440px) {
  .model-page .black .specs-heads {
    width: 50%;
    margin: 2rem auto;
  }
}

.model-page .black .specs-containers {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (50%)[2];
      grid-template-columns: repeat(2, 50%);
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .black .specs-containers {
    width: 50%;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .black .specs-containers {
    width: 50%;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .black .specs-containers {
    width: 50%;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .model-page .black .specs-containers {
    width: 50%;
    margin: 0 auto;
  }
}

.model-page .black .specs-containers div {
  position: relative;
}

.model-page .black .specs-containers div h5 {
  font-size: 0.9rem;
  color: #fff;
  font-weight: 500;
}

.model-page .black .specs-containers div h6 {
  font-size: 0.9rem;
  color: #d6d6d6;
  font-weight: 300;
}

.model-page .black .specs-containers div::before {
  position: absolute;
  content: '';
  top: -1rem;
  left: 0;
  width: 40px;
  height: 1px;
  background: #a7a7a7;
}

.model-page .black h1 {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 4rem;
}

@media (min-width: 1440px) {
  .model-page .black h1 {
    font-size: 2.5rem;
  }
}

.model-page .black button {
  width: 90%;
  border: 1px solid #fff;
  padding: 0.8rem 0;
  color: #fff;
  background: none;
  border-radius: 22px;
  margin: 1rem auto;
  display: block;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .black button {
    width: 300px;
  }
}

@media (min-width: 1440px) {
  .model-page .black button {
    width: 300px;
  }
}

.model-page .black button:hover {
  color: #282828;
  background: #fff;
}

.model-page .mit1 {
  max-width: 100%px;
  /* background: #000; */
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .mit1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    padding-right: 5rem;
    
  }
  .model-page .mit1 img {
    margin-top: -4rem;
  }
  .model-page .mit1 ul {
    padding-left: 5rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1440px) {
  .model-page .mit1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    padding-right: 5rem;
  }
  .model-page .mit1 img {
    margin-top: -4rem;
  }
  .model-page .mit1 ul {
    padding-left: 5rem;
    padding-right: 2rem;
  }
}

.model-page .mit2 {
  max-width: 1500px;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .mit2 {
    width: 80%;
    margin: 2rem auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 3rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media (min-width: 1440px) {
  .model-page .mit2 {
    width: 80%;
    margin: 2rem auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 3rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.model-page .mit3 {
  max-width: 1500px;
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .mit3 {
    width: 80%;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 3rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 2rem 0;
  }
}

@media (min-width: 1440px) {
  .model-page .mit3 {
    width: 80%;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 3rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 2rem 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .specs-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media (min-width: 1440px) {
  .specs-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .black-container-parent {
    width: 100%;
    background: #000;
  }
}

@media (min-width: 1440px) {
  .black-container-parent {
    width: 100%;
    background: #000;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .black-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[3];
        grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .black-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[3];
        grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}


@media (min-width: 1280px) and (max-width: 1439px) {
  .black-container1 {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .black-container1 {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}




@media (min-width: 1280px) and (max-width: 1439px) {
  .white-container-parent {
    width: 100%;
    background: #ffffff;
    padding-bottom: 10px;
  }
}

@media (min-width: 1440px) {
  .white-container-parent {
    width: 100%;
    background: #ffffff;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .white-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[3];
        grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .white-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[3];
        grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .white-container1 {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .white-container1 {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}


.specs-parent {
  max-width: 1800px;
}

@media (min-width: 1440px) {
  .specs-parent {
    margin: 0 auto !important;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (50%)[2];
        grid-template-columns: repeat(2, 50%);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.specs-parent button {
  border: 1px solid #fff !important;
  color: #fff !important;
  width: 60% !important;
  background: transparent !important;
  -webkit-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important;
}

.specs-parent button:hover {
  background: #fff !important;
  color: #000 !important;
}

.not-found {
  height: 100vh;
  display: -ms-grid;
  display: grid;
  place-content: center;
}

.not-found h1 {
  font-size: 2rem;
  font-weight: 200;
  text-align: center;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .not-found h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .not-found h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .not-found h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1440px) {
  .not-found h1 {
    font-size: 3rem;
  }
}

.not-found button {
  width: 300px;
  margin: 2rem auto 0;
  display: block;
  padding: 1rem;
  border-radius: 20px;
  outline: none;
  border: none;
  background: #282828;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.whiteheader{

  display: flex;
  justify-content: center;
  color: #fff;
  /* padding: 1rem 0rem; */

}

.blackheader{
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  /* padding: 1rem 0rem; */

}
/* .interiortwo{
  background: url("./../images/bgs/twobedroom/2BED_home.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
} */

/*2 bedroom */

.model-page .interiortwo {
  background: url("./../images/bgs/twobedroom/2BED_home.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiortwo {
    height: 100vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiortwo {
    height: 100vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiortwo {
    height: 100vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiortwo {
    height: 100vh;
  }
}



.model-page .kitchentwo {
  background: url("./../images/bgs/twobedroom/2BEDV2_KTCHN_LG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .kitchentwo {
    height: 70vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .kitchentwo {
    height: 70vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .kitchentwo {
    height: 70vh;
  }
}

@media (min-width: 1440px) {
  .model-page .kitchentwo {
    height: 70vh;
  }
}


/*threebedroom*/


.model-page .interiorthree {
  background: url("./../images/bgs/threebedstandard/__3BED_design.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}


.model-page .interiorfour {
  background: url("./../images/bgs/fourbedstandard/__4BEDmiddle.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}

@media (min-width: 1440px) {
  .model-page .interiorthree {
    height: 100vh;
  }
}
