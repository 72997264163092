@import './name';
@import './priceSelector';
@import './specs';
@import './paintSelector';
@import './wheelSelector';
@import './rims';
@import './newFeatures';
@import './interiors';
@import './finalPrice';
@import './selectedModelSpecs';
@import './paymenMetod';

.model-info {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 3rem;

  @include Laptops() {
    margin-top: 5rem;
  }

  @include HiDPI() {
    margin-top: 5rem;
    max-width: 400px;
  }

  .order-btn {
    width: 100%;
    margin: 0 auto;
    padding: 0.6rem 0;
    display: block;
    border-radius: 22px;
    border: 2px solid black;
    background: #fff;
    color: black;
    font-size: 1rem;
    outline: none;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  .order-btn:hover {
    background: #282828;
    color: #fff;
  }
}
