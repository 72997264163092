@import './../../media-colors/mediaquerries';
@import './Nav/cNav';
@import './loader';
@import './Info/modelInfo';
@import './Info/yourModelContainer';
@import './Info/checkout';

// Promena layout kompozicije zbog vecih ekrana
.model-container-large {
  @include Laptops() {
    width: 100%;
    height: 100%;
    display: flex;
  }

  @include HiDPI() {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .slider {
    display: none;

    @include Laptops() {
      display: block;
      width: 70vw;
      height: 100vh;
      position: relative;

      img {
        height: 100%;
        width: inherit;
        object-fit: contain;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .slider-btn-container {
        position: fixed;
        top: 0;
        left: 0;
        height: inherit;
        width: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
      }

      .interior {
        height: 100vh;
        object-fit: cover;
      }
    }

    @include HiDPI() {
      display: block;
      width: 70%;
      height: 100vh;
      position: relative;

      img {
        height: inherit;
        width: inherit;
        object-fit: contain;
        position: fixed;
        top: 0;
        left: 0;
      }

      .slider-btn-container {
        position: fixed;
        top: 45%;
        height: 100px;
        width: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;

        button {
          border-radius: 50%;
          border: none;
          outline: none;
          display: grid;
          place-content: center;
          // padding: 1rem;
          font-size: 1.8rem;
          background: rgb(240, 240, 240);
          cursor: pointer;
        }
      }

      .interior {
        height: 100vh;
        object-fit: cover;
      }
    }
  }
}

.model-box {
  position: relative;
  width: 100%;
  height: 100vh;

  @include Laptops() {
    width: 30vw;
    height: 100vh;
  }

  @include HiDPI() {
    width: 30%;
    height: 100vh;
  }

  img {
    width: inherit;
    height: auto;

    @include Laptops() {
      display: none;
    }

    @include HiDPI() {
      display: none;
    }
  }
}
