.cash-payment {
  margin: 1.5rem 0;

  p {
    font-size: 0.9rem;
    color: #797979;
    line-height: 1.2rem;
    margin: 0.2rem 0;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin: 0.8rem 0;

    h3 {
      font-weight: 500;
      font-size: 1rem;
    }

    h4 {
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
}
