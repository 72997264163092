h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500;
}

.paint-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .color-border {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    background: transparent;
    border: 2px solid transparent;
    transition: border 0.6s linear;

    .color {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      background-position: center;
      background-size: cover;

      img {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        display: block;
      }
    }
  }

  .show {
    border: 2px solid blue;
  }
}

.paint-info {
  text-align: center;
  margin: 1.5rem 0;

  h3 {
    font-weight: 500;
    font-size: 1rem;
  }

  h6 {
    font-weight: 500;
    margin: 0.5rem 0;
    font-size: 0.9rem;
  }
}
