.price-selector {
  background: rgb(240, 240, 240);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 19px;
  padding: 0.3rem 0.3rem;

  .price-btn {
    position: relative;
    padding: 0.4rem 2.4rem;
    z-index: 113;

    h5 {
      font-weight: 500;
      font-size: 0.8rem;
      color: rgb(80, 80, 80);
      z-index: 112;
      text-align: center;
    }

    .dropback {
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      z-index: -1;
      width: 100%;
      height: 100%;
    }
  }
}
