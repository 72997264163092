.loader {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: grid;
  place-content: center;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  img {
    width: 200px;
    height: 100px;
  }
}
