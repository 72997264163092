@import './../components/Description1';
@import './../components/Description2';

.model-page {
  .model-banner {
    display: grid;
    place-content: center;
    height: 100vh;
  }

  .model-a3 {
    background: url('./../images/bgs/a3/a3-sm-bg.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    @include Pads() {
      background: url('./../images/bgs/a3/a3-lg-bg.jpeg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }

    @include PadsPro() {
      background: url('./../images/bgs/a3/a3-lg-bg.jpeg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }

    @include Laptops() {
      background: url('./../images/bgs/a3/a3-lg-bg.jpeg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }

    @include HiDPI() {
      background: url('./../images/bgs/a3/a3-lg-bg.jpeg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }
  }

  .model-s3 {
    background: url('./../images/bgs/a3/a3bg-3.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;

    @include Pads() {
      height: 70vh;
    }

    @include PadsPro() {
      height: 70vh;
    }

    @include Laptops() {
      height: 75vh;
    }

    @include HiDPI() {
      height: 75vh;
    }
  }

  .rear-a3 {
    background: url('./../images/bgs/a3/a3bg-1.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;

    @include Pads() {
      height: 70vh;
    }
    @include PadsPro() {
      height: 70vh;
    }

    @include Laptops() {
      height: 70vh;
    }

    @include HiDPI() {
      height: 70vh;
    }
  }

  .interior-a3 {
    background: url('./../images/bgs/a3/a3int3.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;

    @include Pads() {
      height: 70vh;
    }

    @include PadsPro() {
      height: 70vh;
    }

    @include Laptops() {
      height: 75vh;
    }

    @include HiDPI() {
      height: 75vh;
    }
  }

  .interior2-a3 {
    background: url('./../images/bgs/a3/a3bg-4.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
  }

  .interior3-a3 {
    background: url('./../images/bgs/a3/a3int.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
  }

  .interior4-a3 {
    background: url('./../images/bgs/a3/a3int2.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
  }

  .interior5-a3 {
    background: url('./../images/bgs/a3/sound.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
  }

  .model-intro-text {
    text-align: left;
    padding: 1rem;
    margin: 2rem auto 0;

    @include Pads() {
      padding: 1rem 2.5rem;
    }

    @include PadsPro() {
      padding: 1rem 2.5rem;
    }

    h5 {
      font-weight: 300;
      font-size: 1.2rem;
      color: #535353;
    }

    h2 {
      text-align: left;
      margin: 0;
      font-weight: 500;
    }

    div {
      margin: 1.5rem 0;

      h3 {
        font-weight: 500;
        font-size: 1rem;

        span {
          background: #282828;
          color: #fff;
          border-radius: 50%;
          padding: 0.2rem 0.5rem;
          margin-right: 0.5rem;
          font-size: 0.7rem;
        }
      }

      p {
        color: #535353;
        line-height: 1.5rem;
      }

      img {
        width: 100%;
        height: auto;
      }

      ul {
        width: 70%;
        margin: -2rem auto 0;

        @include Laptops() {
          width: 100%;
        }

        @include HiDPI() {
          width: 100%;
        }

        li {
          margin: 1.5rem 0;
          list-style: none;

          h3 {
            margin-bottom: 0.5rem;
          }
        }
      }

      button {
        width: 70%;
        margin: 2rem auto;
        display: block;
        background: transparent;
        border: 2px solid #000;
        color: #000;
        padding: 0.6rem 0;
        border-radius: 22px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.5s ease;
      }

      button:hover {
        color: #fff;
        background: #282828;
      }
    }
  }

  // Q8
  .model-q5 {
    background: url('./../images/bgs/q8/sq8-2.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .model-sq5 {
    background: url('./../images/bgs/q8/q8bg.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;

    @include Pads() {
      height: 70vh;
    }

    @include PadsPro() {
      height: 70vh;
    }

    @include Laptops() {
      height: 75vh;
    }

    @include HiDPI() {
      height: 75vh;
    }
  }

  .rear-q5 {
    background: url('./../images/bgs/q8/q8bg-3.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;

    @include Pads() {
      height: 70vh;
    }
    @include PadsPro() {
      height: 70vh;
    }

    @include Laptops() {
      height: 70vh;
    }

    @include HiDPI() {
      height: 70vh;
    }
  }

  .interior-q5 {
    background: url('./../images/bgs/q8/q8int.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;

    @include Pads() {
      height: 70vh;
    }

    @include PadsPro() {
      height: 70vh;
    }

    @include Laptops() {
      height: 75vh;
    }

    @include HiDPI() {
      height: 75vh;
    }
  }

  .interior2-q8 {
    background: url('./../images/bgs/q8/q8int-2.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
  }

  .interior3-q8 {
    background: url('./../images/bgs/q8/q8int-5.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 30vh;

    @include Pads() {
      height: 50vh;
    }

    @include PadsPro() {
      height: 50vh;
    }

    @include Laptops() {
      height: 60vh;
    }

    @include HiDPI() {
      height: 60vh;
    }
  }

  .interior4-q8 {
    background: url('./../images/bgs/q8/q8int-4.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 30vh;

    @include Pads() {
      height: 50vh;
    }

    @include PadsPro() {
      height: 50vh;
    }
    @include Laptops() {
      height: 60vh;
    }

    @include HiDPI() {
      height: 60vh;
    }
  }

  .interior5-q8 {
    background: url('./../images/bgs/q8/q8int-3.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 30vh;

    @include Pads() {
      height: 50vh;
    }

    @include PadsPro() {
      height: 50vh;
    }

    @include Laptops() {
      height: 60vh;
    }

    @include HiDPI() {
      height: 60vh;
    }
  }

  .black {
    background: black;
    color: #fff;
    margin: 0rem 0rem;
    padding: 2rem 1rem;

    @include Pads() {
      padding: 2rem 2.5rem;
    }

    @include PadsPro() {
      padding: 2rem 2.5rem;
    }

    @include Laptops() {
      padding: 2rem 5rem;
    }

    @include HiDPI() {
      padding: 2rem 5rem;
    }

    h2 {
      span {
        font-weight: 300;
      }
    }

    p {
      color: rgb(214, 214, 214);
    }

    div {
      p {
        color: rgb(214, 214, 214);
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    .specs-heads {
      @include Pads() {
        width: 50%;
        margin: 2rem auto;
      }

      @include PadsPro() {
        width: 50%;
        margin: 2rem auto;
      }

      @include Laptops() {
        width: 50%;
        margin: 2rem auto;
      }

      @include HiDPI() {
        width: 50%;
        margin: 2rem auto;
      }
    }

    .specs-containers {
      display: grid;
      grid-template-columns: repeat(2, 50%);

      @include Pads() {
        width: 50%;
        margin: 0 auto;
      }

      @include PadsPro() {
        width: 50%;
        margin: 0 auto;
      }

      @include Laptops() {
        width: 50%;
        margin: 0 auto;
      }

      @include HiDPI() {
        width: 50%;
        margin: 0 auto;
      }

      div {
        position: relative;

        h5 {
          font-size: 0.9rem;
          color: #fff;
          font-weight: 500;
        }

        h6 {
          font-size: 0.9rem;
          color: rgb(214, 214, 214);
          font-weight: 300;
        }

        &::before {
          position: absolute;
          content: '';
          top: -1rem;
          left: 0;
          width: 40px;
          height: 1px;
          background: #a7a7a7;
        }
      }
    }

    h1 {
      font-size: 1.5rem;
      text-align: center;
      margin-top: 4rem;

      @include HiDPI() {
        font-size: 2.5rem;
      }
    }

    button {
      width: 90%;
      border: 1px solid #fff;
      padding: 0.8rem 0;
      color: #fff;
      background: none;
      border-radius: 22px;
      margin: 1rem auto;
      display: block;
      cursor: pointer;
      transition: all 0.5s ease;

      @include Laptops() {
        width: 300px;
      }

      @include HiDPI() {
        width: 300px;
      }
    }

    button:hover {
      color: #282828;
      background: #fff;
    }
  }

  .mit1 {
    max-width: 1500px;
    @include Laptops() {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 5rem;

      img {
        margin-top: -4rem;
      }

      ul {
        padding-left: 5rem;
        padding-right: 2rem;
      }
    }

    @include HiDPI() {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 5rem;

      img {
        margin-top: -4rem;
      }

      ul {
        padding-left: 5rem;
        padding-right: 2rem;
      }
    }
  }

  .mit2 {
    max-width: 1500px;
    @include Laptops() {
      width: 80%;
      margin: 2rem auto;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 3rem;
      justify-content: space-between;
    }

    @include HiDPI() {
      width: 80%;
      margin: 2rem auto;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 3rem;
      justify-content: space-between;
    }
  }

  .mit3 {
    max-width: 1500px;

    @include Laptops() {
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 3rem;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0;
    }

    @include HiDPI() {
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 3rem;
      justify-content: space-between;
      padding: 2rem 0;
      align-items: center;
    }
  }
}

.specs-flex {
  @include Laptops() {
    display: flex;
    justify-content: space-around;
  }

  @include HiDPI() {
    display: flex;
    justify-content: space-around;
  }
}

// Only larger
.black-container-parent {
  @include Laptops() {
    width: 100%;
    background: #000;
  }
  @include HiDPI() {
    width: 100%;
    background: #000;
  }
}
.black-container {
  @include Laptops() {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }

  @include HiDPI() {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    max-width: 1800px;
    margin: 0 auto;
  }
}
.specs-parent {
  max-width: 1800px;
  @include HiDPI() {
    margin: 0 auto !important;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
    justify-content: space-between;
  }

  button {
    border: 1px solid #fff !important;
    color: #fff !important;
    width: 60% !important;
    background: transparent !important;
    transition: all 0.5s ease !important;
  }

  button:hover {
    background: #fff !important;
    color: #000 !important;
  }
}
