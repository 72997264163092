.wheel-selector {
  .wheel {
    h4 {
      font-size: 0.9rem;
      font-weight: 500;
    }

    .motor {
      border: 2px solid #949494;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0;
      padding: 1rem 1rem;
      border-radius: 30px;
      transition: border 0.5s ease;
      cursor: pointer;

      h5 {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }

    .checked {
      border: 2px solid rgb(41, 91, 255);
    }
  }

  .learn-more {
    margin: 1.4rem 0;

    h6 {
      font-size: 0.8rem;
      font-weight: 400;
      color: rgb(40, 40, 40);

      span {
        border-bottom: 1px solid rgb(120, 120, 120);
        color: rgb(120, 120, 120);
        font-weight: 300;
        cursor: pointer;
      }
    }
  }
}

.price-custom {
  p {
    text-align: center;
    font-size: 0.8rem;
    color: #949494;
    margin: 1rem 0;
    line-height: 1.3rem;
  }

  button {
    background: rgb(240, 240, 240);
    color: #000;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    margin: 2rem auto;
    display: block;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
  }
}
